'use client';
import { Button } from '@/components/ui';
import { DialogFooter } from '@/components/ui/dialog';
import {
  compileFullName,
  convertHoursToTime,
  getColorForServiceRole,
  openAddressInGoogleMaps,
  transformApiDateToViewDate,
  transformDateToViewDate,
} from '@/lib/utils';
import { useTranslations } from 'next-intl';
import {
  StatisticBadge,
  Chips,
  ConfirmDialog,
  LabelValueItem,
  toast,
  ToastTypeEnums,
  ToggleView,
  ConfirmWithTextareaDialog,
  ConfirmWithTextareaDialogRef,
} from '@/components/common';
import { Service } from '../interfaces/services.interface';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import Calendar from './../../../../public/icons/calendar.svg';
import Clock from './../../../../public/icons/clock.svg';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { Colors } from '@/common/enums';
import { createRef } from 'react';
import { useApproveAuthorizationMutation } from '@/hooks/fetchers/mutations/services/useApproveAuthorizationMutation';
import { useSetOnHoldServiceMutation } from '@/hooks/fetchers/mutations/services/useSetOnHoldServiceMutation';
import { useUnsetOnHoldServiceMutation } from '@/hooks/fetchers/mutations/services/useUnsetOnHoldServiceMutation';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import dayjs from '@/lib/dayjsConfig';
import { USER_ROLES_COLORS, STATES_TYPES } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { PreviewGuardianDialog, PreviewGuardianDialogRef } from '@/views/all-guardians/preview/preview-guardian.dialog';
import { useReinstateServiceMutation } from '@/hooks/fetchers/mutations/services/useReinstateServiceMutation';
import { useTerminateServiceMutation } from '@/hooks/fetchers/mutations/services/useTerminateServiceMutation';
import { ReinstateConfirmDialog, ReinstateConfirmDialogRef } from './reinstate-confirm.dialog';
import { useDeclineServiceMutation } from '@/hooks/fetchers/mutations/services/useDeclineServiceMutation';

interface Props {
  service?: Service;
  onEdit: () => void;
  onRefresh: () => void;
}

export function ServiceDetailsTab({ service, onEdit, onRefresh }: Props) {
  const t = useTranslations();
  const { checkPermissions, session, currentRole } = useIsAllowed();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const previewGuardianDialogRef = createRef<PreviewGuardianDialogRef>();
  const reinstateConfirmDialogRef = createRef<ReinstateConfirmDialogRef>();
  const declineConfirmDialogRef = createRef<ConfirmWithTextareaDialogRef>();
  const terminateConfirmDialogRef = createRef<ConfirmWithTextareaDialogRef>();
  const onHoldConfirmDialogRef = createRef<ConfirmWithTextareaDialogRef>();

  const { mutate: decline, isPending: isPendingDecline } = useDeclineServiceMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.declineService'),
      });
      declineConfirmDialogRef.current?.close();
    },
  });

  const { mutate: approveAuthorization, isPending: isPendingApproveAuthorization } = useApproveAuthorizationMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: data.shiftsArchivedMessage ? data.shiftsArchivedMessage : t('Toasts.therapistAssigned'),
      });
      onRefresh();
    },
  });

  const { mutate: setOnHold, isPending: isPendingSetOnHold } = useSetOnHoldServiceMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.setOnHoldService'),
      });
      onRefresh();
    },
  });

  const { mutate: reinstateService, isPending: isPendingReinstate } = useReinstateServiceMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.reinstateService'),
      });
      onRefresh();
    },
  });

  const { mutate: terminate, isPending: isPendingTerminate } = useTerminateServiceMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.terminateService'),
      });
      onRefresh();
    },
  });

  const { mutate: unsetOnHold, isPending: isPendingUnsetOnHold } = useUnsetOnHoldServiceMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.unsetOnHoldService'),
      });
      onRefresh();
    },
  });

  const declineService = () => {
    declineConfirmDialogRef.current?.open();
  };

  const terminateService = () => {
    terminateConfirmDialogRef.current?.open();
  };

  const onHoldService = () => {
    onHoldConfirmDialogRef.current?.open();
  };

  const previewClient = (id: string) => {
    previewClientDialogRef.current?.openById(id);
  };

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  const previewGuardian = (id: string) => {
    previewGuardianDialogRef.current?.openById(id);
  };

  return (
    <>
      <div>
        <div className="relative max-w-[1000px]">
          <div className="absolute right-4 top-0 flex flex-col space-y-2">
            <StatisticBadge
              svgPath={Calendar}
              label={t('Pages.AllServices.dateAdded')}
              value={service?.createdAt ? dayjs(service.createdAt).format('MM.DD.YYYY') : '---'}
            />
            <StatisticBadge
              svgPath={Calendar}
              label={t('Pages.AllServices.initialVisit')}
              value={service?.initialVisitDate ? dayjs(service.initialVisitDate).format('MM.DD.YYYY') : '---'}
            />
            <StatisticBadge
              svgPath={Clock}
              label={t('Pages.AllServices.hoursWorkedInMonth')}
              value={service?.hoursProvided ? `${convertHoursToTime(service.hoursProvided)}` : '---'}
            />
          </div>
          <ScrollArea className="h-[calc(100vh-364px)] overflow-auto">
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.title.label')} value={service?.title} />
              <LabelValueItem label={t('Forms.client.label')} withoutBorder>
                <Chips
                  onClickAction={() => (service && service.client ? previewClient(service.client.id) : null)}
                  color={Colors.indigo}
                  title={compileFullName(service?.client)}
                />
              </LabelValueItem>

              {service?.therapist && (
                <LabelValueItem label={t('Forms.therapist.label')} withoutBorder>
                  <Chips
                    color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]}
                    title={compileFullName(service.therapist)}
                    onClickAction={
                      checkPermissions([PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE])
                        ? () => previewTherapist(service.therapist?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              )}

              <LabelValueItem label={t('Forms.stage.label')} value={service?.status} />
              <LabelValueItem label={t('Forms.authorizationNumber.label')} value={service?.authorizationNumber} />
              <LabelValueItem label={t('Forms.monthly.label')} value={service?.monthly} />
              <LabelValueItem label={t('Forms.frequency.label')} value={`${service?.hours}h/${service?.frequency}`} />
              <LabelValueItem label={t('Forms.type.label')} value={service?.type} />
              <LabelValueItem
                label={t('Forms.authorizedFrom.label')}
                value={transformApiDateToViewDate(service?.authorizedFrom)}
              />
              <LabelValueItem
                label={t('Forms.authorizedTo.label')}
                value={transformDateToViewDate(service?.authorizedTo, false)}
              />
              <LabelValueItem label={t('Forms.programNumber.label')} value={service?.programNumber} />

              {service?.client.addresses.map((value) => (
                <LabelValueItem
                  key={value.id}
                  label={t('Forms.address.label')}
                  value={value.address}
                  onClickByValue={value ? () => openAddressInGoogleMaps(value) : undefined}
                />
              ))}
              <LabelValueItem
                label={t('Forms.speakingLanguage.label')}
                value={
                  service && service.languages && service.languages.length > 0
                    ? service.languages.join(', ')
                    : undefined
                }
              />
              <LabelValueItem label={t('Forms.serviceRole.label')} withoutBorder>
                <Chips
                  color={getColorForServiceRole(service?.serviceRole.title, service?.serviceRole.speciality.code)}
                  title={`${service?.serviceRole.title} ${service?.serviceRole.regionalCenter ? '(' + service?.serviceRole.regionalCenter.substring(0, 3) + ')' : ''}`}
                />
              </LabelValueItem>
              <LabelValueItem label={t('Forms.guardian.label')}>
                <div className="space-x-2">
                  {service &&
                    service.guardians &&
                    service.guardians.map((guardian) => (
                      <Chips
                        onClickAction={() => previewGuardian(guardian.id)}
                        key={guardian.id}
                        color={USER_ROLES_COLORS[ROLE_TYPES.Guardian]}
                        title={compileFullName(guardian)}
                      />
                    ))}
                </div>
              </LabelValueItem>
            </div>

            <div className="mt-4 grid grid-cols-form-cols-2 gap-8">
              <div className="ml-2">
                <ToggleView label={t('Forms.inPersonOnly.label')} value={service?.inPersonOnly ?? false} />
              </div>
              <div className="ml-2">
                <ToggleView label={t('Forms.geofencing.label')} value={service?.geofencingOn ?? false} />
              </div>
            </div>
          </ScrollArea>
        </div>
        <DialogFooter className="mt-10 flex h-20 w-full flex-row items-center justify-between space-x-2 border-t border-t-gray-300 sm:justify-between">
          {!service?.deletedAt ? (
            <>
              <div className="space-x-4">
                {checkPermissions([PERMISSIONS_TYPES.DECLINE_SERVICE]) &&
                (currentRole !== ROLE_TYPES.Admin && currentRole !== ROLE_TYPES['Super admin']
                  ? service?.therapist && service.therapist.id === session?.user.userId
                  : true) &&
                (STATES_TYPES.CONTACTING_PARENTS === service?.status ||
                  STATES_TYPES.THERAPIST_ASSIGNED === service?.status ||
                  STATES_TYPES.PENDING_APPROVAL === service?.status) ? (
                  <Button
                    type="button"
                    variant="outlineDestructive"
                    size="lg"
                    className="mt-5"
                    disabled={isPendingUnsetOnHold}
                    onClick={declineService}
                  >
                    {t('Buttons.declineService')}
                  </Button>
                ) : null}

                {service &&
                STATES_TYPES.ON_HOLD !== service?.status &&
                STATES_TYPES.TERMINATED !== service?.status &&
                checkPermissions([PERMISSIONS_TYPES.MANAGE_ON_HOLD_SERVICES]) ? (
                  <Button
                    onClick={onHoldService}
                    type="button"
                    variant="outlineDestructive"
                    size="lg"
                    className="mt-5"
                    disabled={isPendingSetOnHold}
                  >
                    {t('Buttons.onHold')}
                  </Button>
                ) : null}

                {service &&
                STATES_TYPES.TERMINATED !== service.status &&
                checkPermissions([PERMISSIONS_TYPES.TERMINATE_SERVICES]) ? (
                  <Button
                    onClick={terminateService}
                    type="button"
                    variant="outlineDestructive"
                    size="lg"
                    className="mt-5"
                    disabled={isPendingTerminate}
                  >
                    {t('Buttons.terminate')}
                  </Button>
                ) : null}

                {service &&
                STATES_TYPES.TERMINATED === service.status &&
                checkPermissions([PERMISSIONS_TYPES.REINSTATE_SERVICES]) ? (
                  // <ConfirmDialog
                  //   onConfirm={() => reinstateService({ serviceId: service.id })}
                  //   title={t('Common.reinstateServiceTitle')}
                  //   type="warning"
                  //   description={t('Common.confirmReinstateService')}
                  // >
                  <Button
                    type="button"
                    variant="outlineDestructive"
                    size="lg"
                    className="mt-5"
                    onClick={() => reinstateConfirmDialogRef.current?.open(service.authorizedTo)}
                    disabled={isPendingReinstate}
                  >
                    {t('Buttons.reinstate')}
                  </Button>
                ) : // </ConfirmDialog>
                null}

                {service &&
                STATES_TYPES.ON_HOLD === service?.status &&
                checkPermissions([PERMISSIONS_TYPES.MANAGE_ON_HOLD_SERVICES]) ? (
                  <ConfirmDialog
                    onConfirm={() => unsetOnHold({ serviceId: service.id })}
                    title={t('Common.onActiveServiceTitle')}
                    type="warning"
                    description={t('Common.confirmOnActiveService')}
                  >
                    <Button type="button" variant="outline" size="lg" className="mt-5">
                      {t('Buttons.activate')}
                    </Button>
                  </ConfirmDialog>
                ) : null}
              </div>

              <div className="space-x-4">
                {STATES_TYPES.PENDING_APPROVAL === service?.status &&
                checkPermissions([PERMISSIONS_TYPES.AUTHORIZATION_OF_THE_SERVICE]) &&
                (currentRole !== ROLE_TYPES.Admin && currentRole !== ROLE_TYPES['Super admin']
                  ? service.therapist && service.therapist.id === session?.user.userId
                  : true) ? (
                  <ConfirmDialog
                    onConfirm={() => approveAuthorization(service.id)}
                    title={t('Common.confirmApproveAuthorizationTitle')}
                    type="warning"
                    description={t('Common.confirmApproveAuthorization')}
                  >
                    <Button
                      type="button"
                      variant="secondary"
                      size="lg"
                      className="mt-5"
                      disabled={isPendingApproveAuthorization}
                    >
                      {t('Buttons.authorizeService')}
                    </Button>
                  </ConfirmDialog>
                ) : null}

                {checkPermissions([PERMISSIONS_TYPES.EDIT_SERVICE]) &&
                  service?.status !== STATES_TYPES.TERMINATED &&
                  service?.status !== STATES_TYPES.ON_HOLD && (
                    <Button type="button" size="lg" className="mt-5" onClick={onEdit}>
                      {t('Buttons.edit')}
                    </Button>
                  )}
              </div>
            </>
          ) : null}
        </DialogFooter>
      </div>
      <PreviewClientDialog onUpdated={onRefresh} ref={previewClientDialogRef} />
      <PreviewTherapistDialog onUpdated={onRefresh} ref={previewTherapistDialogRef} />
      <PreviewGuardianDialog onUpdated={onRefresh} ref={previewGuardianDialogRef} />
      <ConfirmWithTextareaDialog
        title={t('Pages.MyClients.declineService')}
        subtitle={t('Pages.MyClients.reasonForDeclining')}
        confirmBtnName={t('Buttons.declineService')}
        isPending={isPendingDecline}
        onConfirm={(note) => decline({ id: service?.id as string, note })}
        ref={declineConfirmDialogRef}
      />
      <ConfirmWithTextareaDialog
        title={t('Pages.AllServices.terminateService')}
        subtitle={t('Pages.AllServices.reasonForTerminate')}
        confirmBtnName={t('Buttons.terminateService')}
        isPending={isPendingTerminate}
        onConfirm={(note) => terminate({ id: service?.id as string, note })}
        ref={terminateConfirmDialogRef}
      />
      <ConfirmWithTextareaDialog
        title={t('Pages.AllServices.onHoldService')}
        subtitle={t('Pages.AllServices.reasonForOnHold')}
        confirmBtnName={t('Buttons.onHold')}
        isPending={isPendingSetOnHold}
        onConfirm={(note) => setOnHold({ id: service?.id as string, note })}
        ref={onHoldConfirmDialogRef}
      />
      <ReinstateConfirmDialog
        confirm={(authorizedTo) => reinstateService({ serviceId: service?.id as string, authorizedTo })}
        ref={reinstateConfirmDialogRef}
      />
    </>
  );
}
