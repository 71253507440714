import { Chips, LabelValueItem, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Document } from '../interfaces/document.interface';
import { Colors, DOCUMENT_STATUS_TYPES, DOCUMENT_TYPE_TYPES } from '@/common/enums';
import { useIsAllowed } from '@/lib/RBAC';
import { cn, compileFullName, getColorForServiceRole, transformApiDateToViewDate } from '@/lib/utils';
import { useApproveDocumentMutation } from '@/hooks/fetchers/mutations/documents/useApproveDocumentMutation';
import { useRejectDocumentMutation } from '@/hooks/fetchers/mutations/documents/useRejectDocumentMutation';
import { CheckCircle2, FileText, PenSquare, X } from 'lucide-react';
import { useSubmitDocumentMutation } from '@/hooks/fetchers/mutations/documents/useSubmitDocumentMutation';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { AcquireFileType } from '../acquire-file-types';
import { useDocumentQuery } from '@/hooks/fetchers/queries/documents/useDocumentQuery';
import { USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { useSession } from 'next-auth/react';
import { useForm } from 'react-hook-form';
import { DatePicker, Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/form';
import dayjs from 'dayjs';
import { EditDocumentDialog, EditDocumentDialogRef } from '../manage/edit-document.dialog';
import { useUpdateDocumentMutation } from '@/hooks/fetchers/mutations/documents/useUpdateDocumentMutation';

interface Props {
  onUpdated?: () => void;
}

export type PreviewDocumentDialogRef = {
  open: (document: Document) => void;
  openById: (id: string) => void;
};

const PreviewDocumentDialog = forwardRef<PreviewDocumentDialogRef, Props>(({ onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const { checkPermissions, currentRole } = useIsAllowed();
  const editDocumentDialogRef = createRef<EditDocumentDialogRef>();
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const [documentData, setDocumentData] = useState<Document | null>(null);
  const [documentId, setDocumentId] = useState<string>();
  const [isEditExpireDateMode, setIsEditExpireDateMode] = useState<boolean>(false);
  const { data: session } = useSession();

  const { data: documentDataById, refetch: refetchDocumentDataById } = useDocumentQuery(documentId, {
    refetchOnWindowFocus: isOpen,
  });

  useEffect(() => {
    if (documentDataById) {
      setDocumentData(documentDataById);
      form.setValue(
        'dueDate',
        documentDataById.expiryDate ? dayjs(documentDataById?.expiryDate, 'YYYY-MM-DD') : dayjs()
      );
    }
  }, [documentDataById]);

  useImperativeHandle(ref, () => ({
    open: (document) => {
      setIsOpen(true);
      setDocumentData(document);
      setDocumentId(document.id);
    },
    openById: (id) => {
      setIsOpen(true);
      setDocumentId(id);
    },
  }));

  useEffect(() => {
    if (!isOpen && documentId) {
      setDocumentId(undefined);
      setIsEditExpireDateMode(false);
    }
  }, [isOpen]);

  const { mutate: approve, isPending: isPendingApprove } = useApproveDocumentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.approveDoc'),
      });
      onUpdated?.();
      setIsOpen(false);
    },
  });

  const { mutate: editExpDate, isPending: isPendingEditExpDate } = useUpdateDocumentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
      onUpdated?.();
      refetchDocumentDataById();
      setIsEditExpireDateMode(false);
    },
  });

  const openReportFile = async () => {
    const { data } = await axiosInterceptorInstance.get(
      `documents/${documentData?.id}/acquire/${AcquireFileType.Report}`
    );
    if (data?.url) {
      window.open(data.url, '_blank', 'noopener,noreferrer');
    } else {
      toast({
        title: t('Toasts.warning'),
        typeIcon: ToastTypeEnums.WARNING,
        description: t('Toasts.notFoundFile'),
      });
    }
  };

  const openNotesFile = async () => {
    const { data } = await axiosInterceptorInstance.get(
      `documents/${documentData?.id}/acquire/${AcquireFileType.Notes}`
    );
    if (data?.url) {
      window.open(data.url, '_blank', 'noopener,noreferrer');
    } else {
      toast({
        title: t('Toasts.warning'),
        typeIcon: ToastTypeEnums.WARNING,
        description: t('Toasts.notFoundFile'),
      });
    }
  };

  const { mutate: submit, isPending: isPendingSubmit } = useSubmitDocumentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.submitDoc'),
      });
      onUpdated?.();
      setIsOpen(false);
    },
  });

  const { mutate: reject, isPending: isPendingReject } = useRejectDocumentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.rejectDoc'),
      });
      onUpdated?.();
      setIsOpen(false);
    },
  });

  const previewClient = (id: string) => {
    previewClientDialogRef.current?.openById(id);
  };

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  const previewService = (id: string) => {
    previewServiceDialogRef.current?.openById(id, false);
  };

  const form = useForm({
    mode: 'onTouched',
    defaultValues: {
      dueDate:
        documentDataById && documentDataById.expiryDate ? dayjs(documentDataById?.expiryDate, 'YYYY-MM-DD') : dayjs(),
    },
  });

  const onSubmit = async (value: { dueDate: dayjs.Dayjs }) => {
    editExpDate({
      id: documentDataById?.id as string,
      dueDate: value.dueDate ? value.dueDate.format('YYYY-MM-DD') : '',
      isOnlyEditExpData: true,
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllDocuments.previewDocument')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <ScrollArea className="h-[calc(100vh-260px)] overflow-auto pr-5">
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              {!(
                (currentRole === ROLE_TYPES.Assistant || currentRole === ROLE_TYPES['Assistant Limited']) &&
                (documentData?.type === DOCUMENT_TYPE_TYPES.Discharge ||
                  documentData?.type === DOCUMENT_TYPE_TYPES.Progress)
              ) ||
              ((currentRole === ROLE_TYPES.Assistant || currentRole === ROLE_TYPES['Assistant Limited']) &&
                documentData?.status === DOCUMENT_STATUS_TYPES.Completed) ? (
                <div
                  onClick={openReportFile}
                  className={cn(
                    'flex min-h-14 max-w-80 cursor-pointer flex-row items-center justify-start space-x-4 rounded-sm transition-colors duration-300 hover:bg-blue-50',
                    !documentData?.fileUrl && 'pointer-events-none opacity-35'
                  )}
                >
                  <div className="flex flex-col items-center justify-center rounded-sm bg-blue-100 p-2">
                    <FileText className="size-8 text-blue-600" />
                  </div>
                  <span className="px-2 text-base">
                    {documentData?.fileName ? documentData?.fileName : t('Buttons.reportFile')}
                  </span>
                </div>
              ) : null}
              {documentData?.therapist && documentData.therapist.supervisor ? (
                <div
                  onClick={openNotesFile}
                  className={cn(
                    'flex min-h-14 max-w-80 cursor-pointer flex-row items-center justify-start space-x-4 rounded-sm transition-colors duration-300 hover:bg-blue-50',
                    !documentData?.notesFileUrl && 'pointer-events-none opacity-35'
                  )}
                >
                  <div className="flex flex-col items-center justify-center rounded-sm bg-blue-100 p-2">
                    <FileText className="size-8 text-blue-600" />
                  </div>
                  <span className="px-2 text-base">
                    {documentData?.notesFileName ? documentData?.notesFileName : t('Buttons.notesFile')}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="my-6 flex w-full max-w-[676px] flex-col space-y-6">
              {documentData?.client && (
                <LabelValueItem label={t('Forms.client.label')} withoutBorder>
                  <Chips
                    onClickAction={() => previewClient(documentData.client.id)}
                    color={Colors.indigo}
                    title={compileFullName(documentData?.client)}
                  />
                </LabelValueItem>
              )}

              {documentData?.therapist && (
                <LabelValueItem label={t('Forms.therapist.label')} withoutBorder>
                  <Chips
                    color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]}
                    title={compileFullName(documentData?.therapist)}
                    onClickAction={
                      checkPermissions([
                        PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS,
                        PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE,
                      ])
                        ? () => previewTherapist(documentData.therapist?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              )}

              {documentData?.therapist && documentData.therapist.supervisor ? (
                <LabelValueItem label={t('Forms.supervisor.label')} withoutBorder>
                  <Chips
                    color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]}
                    title={compileFullName(documentData?.therapist.supervisor)}
                  />
                </LabelValueItem>
              ) : null}

              {documentData?.service && documentData?.service.serviceRole ? (
                <LabelValueItem label={t('Forms.service.label')} withoutBorder>
                  <Chips
                    warningBadge={documentData.service.deletedAt && t('Common.archived')}
                    color={getColorForServiceRole(
                      documentData?.service.serviceRole.title,
                      documentData.service.serviceRole.speciality?.code
                    )}
                    title={documentData.service ? documentData.service.name : '---'}
                    onClickAction={
                      checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS]) ||
                      session?.user.userId === documentData.therapist?.id // only for assigned therapist
                        ? () => previewService(documentData.service?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              ) : null}
            </div>
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.reportType.label')} value={documentData?.type} />
              {/* @TODO: Change Past due to Expired on BE */}
              <LabelValueItem label={t('Forms.status.label')} value={documentData?.status} />
              <div className="flex flex-row items-end justify-between">
                {!isEditExpireDateMode && (
                  <>
                    <LabelValueItem
                      label={t('Forms.dueDate.label')}
                      value={transformApiDateToViewDate(documentData?.expiryDate)}
                    />
                    {checkPermissions([PERMISSIONS_TYPES.EDIT_DOCUMENT]) &&
                    (documentData?.type === DOCUMENT_TYPE_TYPES.Discharge ||
                      documentData?.type === DOCUMENT_TYPE_TYPES.Progress) ? (
                      <Button
                        onClick={() => setIsEditExpireDateMode(true)}
                        type="button"
                        size="iconSm"
                        variant="ghost"
                        className="ml-1 min-w-10"
                      >
                        <PenSquare />
                      </Button>
                    ) : null}
                  </>
                )}

                {isEditExpireDateMode && (
                  <>
                    <Form {...form}>
                      <form
                        className="flex w-full flex-row items-end justify-between"
                        noValidate
                        onSubmit={form.handleSubmit(onSubmit)}
                      >
                        <div className="w-full">
                          <FormField
                            control={form.control}
                            name="dueDate"
                            render={({ field, fieldState }) => (
                              <FormItem>
                                <FormLabel className="ml-1">{t('Forms.dueDate.label')}</FormLabel>
                                <DatePicker isOptional disableDates="none" field={field} isError={fieldState.error} />
                                <FormMessage className="absolute" />
                              </FormItem>
                            )}
                          />
                        </div>
                        <Button
                          onClick={() => setIsEditExpireDateMode(false)}
                          type="button"
                          size="iconSm"
                          variant="ghost"
                          className="ml-1 min-w-10 text-destructive"
                        >
                          <X />
                        </Button>
                        <Button
                          type="submit"
                          disabled={isPendingEditExpDate}
                          size="iconSm"
                          variant="ghost"
                          className="ml-1 min-w-10 text-green-600"
                        >
                          <CheckCircle2 />
                        </Button>
                      </form>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </ScrollArea>
          <DialogFooter className="flex h-20 flex-row items-center justify-between space-x-2 border-t border-t-gray-300 sm:justify-between">
            {!documentData?.deletedAt && (
              <>
                {checkPermissions([PERMISSIONS_TYPES.APPROVE_OR_REJECT_REPORT]) && (
                  <div className="space-x-2">
                    {documentData?.status === DOCUMENT_STATUS_TYPES.Submitted && (
                      <Button
                        type="button"
                        size="lg"
                        variant="outlineDestructive"
                        className="mt-5"
                        onClick={() => reject(documentData?.id as string)}
                        disabled={isPendingReject}
                      >
                        {t('Buttons.reject')}
                      </Button>
                    )}
                    {documentData?.status !== DOCUMENT_STATUS_TYPES.Completed && (
                      <Button
                        type="button"
                        size="lg"
                        variant="outline"
                        className="mt-5"
                        onClick={() => approve(documentData?.id as string)}
                        disabled={isPendingApprove}
                      >
                        {t('Buttons.approve')}
                      </Button>
                    )}
                  </div>
                )}
                <div>
                  {checkPermissions([PERMISSIONS_TYPES.SUBMIT_DOCUMENT]) &&
                  (documentData?.status === DOCUMENT_STATUS_TYPES.Pending ||
                    documentData?.status === DOCUMENT_STATUS_TYPES.Prepared ||
                    documentData?.status === DOCUMENT_STATUS_TYPES['Notes Past due'] ||
                    documentData?.status === DOCUMENT_STATUS_TYPES['Report Past due']) ? (
                    <Button
                      type="button"
                      size="lg"
                      className="mt-5"
                      onClick={() => submit(documentData?.id as string)}
                      disabled={isPendingSubmit}
                    >
                      {t('Buttons.submit')}
                    </Button>
                  ) : null}

                  {checkPermissions([PERMISSIONS_TYPES.PRESUBMIT_DOCUMENT]) &&
                  documentData?.isSubmittedAssistantId === null &&
                  (documentData?.status === DOCUMENT_STATUS_TYPES.Pending ||
                    documentData?.status === DOCUMENT_STATUS_TYPES['Notes Past due'] ||
                    documentData?.status === DOCUMENT_STATUS_TYPES['Report Past due']) ? (
                    <Button
                      type="button"
                      size="lg"
                      className="mt-5"
                      onClick={() => submit(documentData?.id as string)}
                      disabled={isPendingSubmit}
                    >
                      {t('Buttons.submit')}
                    </Button>
                  ) : null}

                  {checkPermissions([PERMISSIONS_TYPES.EDIT_DOCUMENT]) &&
                  (documentData?.type === DOCUMENT_TYPE_TYPES.Other ||
                    documentData?.type === DOCUMENT_TYPE_TYPES.IFSP ||
                    documentData?.type === DOCUMENT_TYPE_TYPES.POS) ? (
                    <Button
                      type="button"
                      size="lg"
                      className="mt-5"
                      onClick={() => editDocumentDialogRef.current?.open(documentDataById as Document)}
                      disabled={isPendingSubmit}
                    >
                      {t('Buttons.edit')}
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <PreviewClientDialog onUpdated={refetchDocumentDataById} ref={previewClientDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchDocumentDataById} ref={previewTherapistDialogRef} />
      <PreviewServiceDialog onUpdated={refetchDocumentDataById} ref={previewServiceDialogRef} />
      <EditDocumentDialog onUpdated={refetchDocumentDataById} ref={editDocumentDialogRef} />
    </>
  );
});

PreviewDocumentDialog.displayName = 'PreviewDocumentDialog';

export { PreviewDocumentDialog };
